// 个人中心
import request from "@/utils/request.js"

// 获取质押融资订单
export const getOrder = (page) => {
  return request({
    method: 'get',
    url: '/order',
    headers: {
      token: true
    },
    params: {
      page
    }
  })
}

// 删除质押融资订单
export const delOrder = (order_id) => {
  return request({
    method: 'get',
    url: '/delorder',
    headers: {
      token: true
    },
    params: {
      order_id
    }
  })
}

// 获取知识产权订单
export const getTradingOrder = (page) => {
  return request({
    method: 'get',
    url: '/asset-order/getorderlist',
    headers: {
      token: true
    },
    params: {
      page
    }
  })
}

// 删除知识产权订单
export const delTradingOrder = (id) => {
  return request({
    method: 'post',
    url: '/asset-order/delorder',
    headers: {
      token: true
    },
    data: {
      id
    }
  })
}

