/** 我的订单 */
<template>
  <div class="my-order">
    <el-table :data="orders" border :height="$isPc ? '630px' : '100%'" v-el-table-infinite-scroll="load"
      v-loading="orderLoading">
      <el-table-column prop="orderId" label="订单编号"> </el-table-column>
      <el-table-column prop="name" label="商品名称"> </el-table-column>
      <el-table-column prop="type" label="类型"> </el-table-column>
      <el-table-column prop="price" label="金额 (元)"> </el-table-column>
      <el-table-column prop="state_text" label="状态"> </el-table-column>
      <el-table-column label="操作" width="210px">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" class="mr-20"
            @click="orderDetails = scope.row; dialogVisible = true">查看详情</el-button>
          <el-popconfirm title="确定删除改订单吗？" @confirm="delTradingOrder(scope.$index, orders, scope.row.id)">
            <el-button type="danger" size="mini" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <Page v-if="$isPc" :total="pageTotal" :pageSize="15" @page="changePage" />

    <el-dialog title="订单详情" :visible.sync="dialogVisible" :lock-scroll="false" @closed="orderDetails = {}">
      <el-descriptions :column="1">
        <el-descriptions-item label="订单类型">{{ orderDetails.type }} </el-descriptions-item>
        <el-descriptions-item label="下单时间">{{ orderDetails.release_at }}</el-descriptions-item>
        <el-descriptions-item label="订单进度">{{ orderDetails.state_text }}</el-descriptions-item>
        <el-descriptions-item label="订单编号">{{ orderDetails.orderId }}</el-descriptions-item>
        <el-descriptions-item label="订单名称">{{ orderDetails.name }}</el-descriptions-item>
        <el-descriptions-item label="总费用">{{ orderDetails.price }}</el-descriptions-item>
        <el-descriptions-item label="合同">
          <el-link v-if="orderDetails.path" type="primary" :href="$base + orderDetails.path" target="_blank">查看</el-link>
          <span v-else>暂无</span>
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import { getTradingOrder, delTradingOrder } from '@/api/my'
import Page from '@/components/Page.vue'
export default {
  name: 'TradingOrder',
  components: {
    Page
  },
  data () {
    return {
      dialogVisible: false,
      orderDetails: {},
      orders: [],
      page: 1,
      pageTotal: 0,
      orderLoading: false,
      finished: false, // 是否加载完成
    }
  },
  activated () {
    this.getTradingOrder(this.page)
  },
  methods: {
    async load () {
      if (!this.$isPc && !this.finished && !this.orderLoading) {
        await this.getTradingOrder(this.page)
        if (this.orders.length >= this.pageTotal) {
          this.finished = true
          return
        }
        this.page += 1
      }
    },
    async getTradingOrder (page) {
      this.orderLoading = true
      try {
        let { data } = await getTradingOrder(page)
        data.data.forEach(v => {
          switch (v['state']) {
            case 1:
              v['state_text'] = '等待对接'
              break;
            case 2:
              v['state_text'] = '对接成功'
              break;
            case 3:
              v['state_text'] = '对接失败'
              break;
            case 4:
              v['state_text'] = '对接中'
              break;
            default:
              break;
          }
          v['price'] = v['price'] == 0 ? '面议' : v['price']
        })
        this.orders = this.$isPc ? data.data : [...this.orders, ...data.data]

        this.pageTotal = data.total
      } catch (error) { }
      this.orderLoading = false
    },
    // 切换分页
    changePage (page) {
      this.getTradingOrder(page)
    },
    // 删除订单
    async delTradingOrder (index, rows, id) {
      try {
        if (!this.$store.getters.userToken) return this.$message.warning('请先登录');
        let { code, msg } = await delTradingOrder(id)
        if (code == 200) {
          this.$message.success(msg);
          rows.splice(index, 1);
        } else {
          this.$message.error(msg);
        }
      } catch (error) { }
    }
  }
}
</script>
<style lang="scss" scoped>
.my-order {
  height: 100%;
}
</style>